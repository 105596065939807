<template>
  <div class="s3m-change_password">
    <v-card width="400px" class="mt-5 mx-auto">
      <v-card-title style="margin-top: 20px;">
        <h2>Passwort ändern</h2>
      </v-card-title>
      <v-card-text>
        <v-form value>
          <!--
          <v-text-field
              :label="$t('username')"
              prepend-icon="mdi-account-circle"
              v-model="username"
              :value="email"
              disabled
          />
          -->
          <!--
          <v-text-field
              :label="$t('email')"
              prepend-icon="mdi-email"
              v-model="email"
              :value="email"
              disabled
          />
          <v-text-field
              :label="$t('firstName')"
              prepend-icon="mdi-account-circle"
              v-model="firstname"
              :value="firstname"
              tabindex="1"
          />
          <v-text-field
              :label="$t('lastName')"
              prepend-icon="mdi-account-circle"
              v-model="lastname"
              tabindex="2"
          />-->
          <v-text-field
            :label="$t('password')"
            prepend-icon="mdi-lock"
            :type="showLogin ? 'text' : 'password'"
            :append-icon="showLogin ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showLogin=!showLogin"
            v-model="password"
            tabindex="3"
          />
          <div class="s3m_password_requirements">{{$t("password_requirements")}}</div>

          <v-text-field
              :label="$t('password repeat')"
              prepend-icon="mdi-lock"
              :type="showLogin ? 'text' : 'password'"
              :append-icon="showLogin ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showLogin=!showLogin"
              v-model="password_2"
              tabindex="4"
          />
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
            color="red"
            class="mx-auto px-4"
            @click="goBack"
            tabindex="5"
        >
          Abbrechen
        </v-btn>
        <v-btn
          color="info"
          class="mx-auto px-4"
          @click="saveUser"
          tabindex="5"
        >
          {{$t('Save')}}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar
      v-model="loginFail"
      color="error"
      multi-line="multi-line"
    >
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>mdi-alert-circle</v-icon>
        <v-layout column>
          <div>{{loginFailMessage}}</div>
        </v-layout>
      </v-layout>
    </v-snackbar>
    <v-snackbar
        v-model="loginSuccess"
        color="success"
        multi-line="multi-line"
    >
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>mdi-alert-circle</v-icon>
        <v-layout column>
          <div>{{loginSuccessMessage}}</div>
        </v-layout>
      </v-layout>
    </v-snackbar>
  </div>
</template>
<script>
import APIService from "@/services/APIService";
import {mapGetters, mapMutations, mapState} from "vuex";
import EventBus from "@/services/EventBus";
import shared from "@/services/shared";
import APIServiceWP from "@/services/APIServiceWP";
import VueCookie from 'vue-cookie';

export default {
  name: "ChangePassord",
  data () {
    return {
      username:'',
      email: '',
      password: '',
      password_2: '',
      firstname: 'abcd',
      lastname: '',
      showLogin: false,
      loginFail: false,
      loginFailMessage: '',
      loginSuccess: false,
      loginSuccessMessage: "",
      isAdminUser: false,
      redirectUri: "https://shop.ausbau-akademie.de?logoutUser=info@s3-medien.de"
    }
  },
  async mounted() {
    EventBus.$emit('showTopImage', true, 280);
    if(this.$route.query.lo && this.$route.query.lo == "1"){
      if(this.$route.query.redirect_uri && this.$route.query.redirect_uri != ""){
        this.redirectUri = this.$route.query.redirect_uri;
      }
      this.doLogout();
      return;
    }
    let res = await APIService.getUserEvents(this.user.id);
    console.log(res.results);
  },
  methods: {
    ...mapMutations(['updateUser',  'updateUserData']),
    ...mapGetters(['isLoggedIn', 'showAdmin']),
    async saveUser () {
      if(this.password != "") {
        if (this.password.length < 8 || !shared.validatePassword(this.password)) {
          this.loginFailMessage = this.$t("password_malformed");
          this.loginFail = true;

          return;
        }
        if ((this.password != this.password_2) || this.password == "") {
          this.loginFailMessage = this.$t("passwords_dont_match");
          this.loginFail = true;
          return;
        }
      }
      let user_new = {};
      //user_new = this.user;
      user_new.email = this.user.email;
      //user_new.loginName = this.user.email;
      user_new.password = this.password != "" ? this.password : this.user.password;
      user_new.firstName = (this.firstname && this.firstname != "") ? this.firstname : " ";
      user_new.lastName = (this.lastname && this.lastname != "") ? this.lastname : " ";
      user_new.companyName = this.user.companyName;
      user_new.id = this.user.id;
      // eslint-disable-next-line no-unused-vars
      APIService.updateUser(user_new).then(async (data) => {
        this.loginSuccess = true;
        this.loginSuccessMessage = this.$t("update_succeeded");
        this.user.firstName = user_new.firstName;
        this.user.lastName = user_new.lastName;
        this.user.email = user_new.email;
        this.user.companyName = user_new.companyName;

        if(this.password != ""){
          await this.syncSites(this.user.email, this.password);
        }

        this.updateUserData(this.user);

        EventBus.$emit('updateUserEvent');

        setTimeout(() => { document.location.href = "https://shop.ausbau-akademie.de/?sso=1"; }, 0);

        // eslint-disable-next-line no-unused-vars
      }).catch(error => {
        this.loginFailMessage = this.$t("update_failed");
        this.loginFail = true;
      })

    },
    setFields: function(){
      this.username = this.user.loginName;
      this.email = this.user.email;
      this.firstname = this.user.firstName;
      this.lastname = this.user.lastName
    },
    validate(value) {
      if (!value.match(/\d/) || !value.match(/[a-zA-Z]/)) {
        return false;
      }
      return true;
    },
    goBack() {
      let url = VueCookie.get('referrer');
      VueCookie.set('referrer', '');
      console.log("referer in cp: "+url);
      if(url == null || !url || url === ''){
        shared.goToLink( 'https://shop.ausbau-akademie.de/', false);
      }
      shared.goToLink( url,false);
    },
    doLogout() {
      console.log("do logout");
      this.$store.dispatch('logout').then(() =>{
        console.log("logged out locally now admin");
        shared.goToLink(process.env.VUE_APP_ADMIN_URL + '/sso/logout?redirect_uri='+encodeURIComponent(this.redirectUri), false);
      })
    },
    updateUser() {
      //console.log("is admin? "+shared.userIsAdmin(this.user.role));
      if (this.user !== null && typeof this.user !== 'undefined' && shared.userIsAdmin(this.user.role)) {
        this.isAdminUser = true;
      } else {
        this.isAdminUser = false;
      }
    },
    openLink(url, popup = false) {
      shared.goToLink(url, popup);
    },
    async syncSites(email, password){
      const user = {};

      user.email = email;
      user.password = password;

      console.log(user);

      let sites = JSON.parse(process.env.VUE_APP_SYNC_SITES);
      for(let i=0; i < sites.length; i++) {
        console.log("sync " + sites[i]);
        APIServiceWP.setAPIClient(sites[i]);
        await APIServiceWP.setUserPassword(user);
      }
      return true;
    },
  },
  computed: {
    ...mapState[{
      refreshToken: (state) => state?.user?.tokens?.refresh
    }],
      ...mapState(['user']),
    },
  created() {
    this.setFields();
    this.updateUser();
  },
  beforeRouteUpdate(){
    this.setFields();
  }
}
</script>

<style>
.theme--light.v-card{
  background-color: transparent;
  color: white;
}
.theme--light.v-label--is-disabled, .theme--light.v-label{
  color:white;
}
.theme--light.v-input{
  color:white !important;
}

</style>
